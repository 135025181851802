@import "@aws-amplify/ui-react/styles.css";
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

body {
  overflow-x: hidden;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #000723;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
  overflow: auto;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  margin: 0;
  padding: 0;
}

:root {
  --amplify-fonts-default-variable: "Poppins";
  --amplify-fonts-default-static: "Poppins";
  --amplify-colors-brand-primary-10: var(--amplify-colors-blue-100);
  --amplify-colors-brand-primary-20: var(--amplify-colors-blue-20);
  --amplify-components-button-hover-background-color:var(--amplify-colors-blue-100);
  --amplify-colors-brand-primary-40: var(--amplify-colors-blue-40);
  --amplify-colors-brand-primary-60: var(--amplify-colors-blue-60);
  --amplify-colors-brand-primary-80: var(--amplify-colors-blue-80);
  --amplify-colors-brand-primary-90: var(--amplify-colors-blue-90);
  --amplify-colors-brand-primary-100: var(--amplify-colors-blue-100);
  --amplify-colors-brand-secondary-10: var(--amplify-colors-neutral-10);
  --amplify-components-button-hover-color: var(--amplify-colors-blue-100);
  --amplify-components-button-link-hover-background-color: var(--amplify-colors-blue-100);
  --amplify-colors-brand-secondary-20: var(--amplify-colors-neutral-20);
  --amplify-colors-brand-secondary-40: var(--amplify-colors-neutral-40);
  --amplify-colors-brand-secondary-60: var(--amplify-colors-neutral-60);
  --amplify-colors-brand-secondary-80: var(--amplify-colors-neutral-80);
  --amplify-colors-brand-secondary-90: var(--amplify-colors-neutral-90);
  --amplify-colors-brand-secondary-100: var(--amplify-colors-neutral-100);
  --amplify-colors-radii-small: 0;
  --amplify-colors-radii-medium: 2px;
  --amplify-colors-radii-large: 4px;
  --amplify-colors-border-primary: var(--amplify-colors-neutral-40);
  --amplify-colors-border-secondary: var(--amplify-colors-neutral-20);
  --amplify-colors-border-tertiary: var(--amplify-colors-neutral-10);
  --amplify-colors-background-secondary: var(--amplify-colors-brand-primary-10);
  --amplify-components-tabs-item-border-color: var(--amplify-colors-neutral-60);
  --amplify-components-authenticator-state-inactive-background-color: var(
    --amplify-colors-neutral-20
  );
  --amplify-components-fieldcontrol-focus-border-color: var(
    --amplify-colors-neutral-60
  );
  --amplify-components-button-primary-background-color: hsl(220deg 90% 18%);
  --amplify-radii-small: 0;
  --amplify-radii-medium: 0;
  --amplify-radii-large: 0;
  --amplify-space-small: 1rem;
  --amplify-space-medium: 1.5rem;
  --amplify-space-large: 2rem;
  --amplify-border-widths-small: 1px;
  --amplify-border-widths-medium: 2px;
  --amplify-border-widths-large: 4px;
}
.amplify-button:hover{
  background: var(--amplify-colors-blue-40)!important;
}

.amplify-input {
  text-align: left;
}

[data-amplify-authenticator] [data-amplify-form] button[type="submit"] {
  border-radius: 30px;
  height: 60px;
  width: 254px;
  margin: auto;
}

[data-amplify-authenticator] [data-amplify-router] {
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 0 14px #7ad7ffb5;
}
@media (max-width: 720px) {
  [data-amplify-authenticator] [data-amplify-router] {
    border-radius: 0;
  }
}
@media (min-width: 720px) {
  .my-sm-32{
    margin-bottom: 32px;
    margin-top: 32px;
  }
}

.amplify-alert__body {
  font-size: 12px;
}

.amplify-text {
  font-size: 16px;
}

.card {
  border-radius: 24px;
  overflow: hidden;
  /*box-shadow: 0 0 34px #7ad7ffb5;*/
}

.cta {
  border-radius: 16px;
  -webkit-box-shadow: 0 8px 6px -6px black;
  -moz-box-shadow: 0 8px 6px -6px black;
  box-shadow: 0 8px 6px -6px black;
  width: 160px;
  font-weight: 900;
  font-size: 20px;
}

.amplify-radio__button {
  height: 20px;
  width: 20px;
  border-color: black;
}

.hyb-sub-heading {
  text-align: center;
  font-weight: 600;
  font-size: 44px;
  line-height: 66px;
}

.text-gradient {
  background: linear-gradient(268.97deg, #1f35ff 1.77%, #33c2ff 99.37%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.hyb-price-card {
  background: linear-gradient(
    64.48deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  backdrop-filter: blur(10px);
  border-radius: 12px;
  min-height: 350px;
  border: none;
  width: 380px;
}
.hyb-manage-card {
  background: linear-gradient(
    64.48deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  backdrop-filter: blur(10px);
  border-radius: 12px;
  height: 350px;
  border: none;
  width: 390px;
}

@media (max-width: 992px) {
  .hyb-price-card {
    width: 100%;
  }
  .hyb-manage-card {
    width: 310px;
  }
}

.hyb-price-cta {
  border: 1px solid rgba(122, 215, 255, 0.6);
  border-radius: 50px;
  color: #7ad7ff;
  height: 56px;
  text-align: center;
  min-width: 100%;
  font-size: 1.25rem;
  margin-top: 1.5rem !important;
}
.hyb-price-fill-cta {
  border-radius: 50px;
  background: #7ad7ff;
  height: 56px;
  text-align: center;
  -webkit-user-select: none;
  min-width: 100%;
  font-size: 1.25rem;
  /*margin-top: 1.5rem !important;*/
  color: #0c0c0c;
}

.text-secondary {
  opacity: 0.75;
}

.hyb-save-chip {
  color: #7ad7ff;
  background: rgba(255, 255, 255, 0.12);
  border-radius: 4px;
  padding: 2px 5px;
}

.hyb-price-tax {
  right: 0;
  position: absolute;
  bottom: -40px;
}

.hyb-close-cta {
  border: 1px solid rgba(122, 215, 255, 0.6);
  border-radius: 50px;
  color: #7ad7ff;
  text-align: center;
}

.MuiDialog-paper {
  background-image: URL("../public/assets/images/background.jpeg");
  background-color: #000723;
}


.hyp-dual-view {
  display: flex;
  flex-direction: row;
}

@media (max-width: 500px) {
  .hyp-dual-view {
    flex-direction: column;
  }
}

.txt-green{
  color: #00E40F;
}

.hyb-glass-card {
  background: linear-gradient(
          64.48deg,
          rgba(255, 255, 255, 0.2) 0%,
          rgba(255, 255, 255, 0) 100%
  );
  -webkit-backdrop-filter: blur(7.5px);
  backdrop-filter: blur(7.5px);
  border-radius: 12px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.hyb-glass-card-bottom {
  position: absolute;
  bottom: 0;
}

